import $ from 'jquery';
import '@fortawesome/fontawesome-free/js/all.js';
import 'bootstrap';
import 'popper.js';
import 'jquery-ui';

import 'bootstrap-table'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'bootstrap-table/dist/bootstrap-table.min.js';
import 'bootstrap-table/dist/extensions/custom-view/bootstrap-table-custom-view.js'
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie.js'


import { Calendar as FullCalendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';


// Adding JQuery into the window variable for global use
window.$ = $;
window.jQuery = $;
window.FullCalendar = FullCalendar;
window.dayGridPlugin = dayGridPlugin;
window.bootstrapPlugin = bootstrapPlugin;
window.interactionPlugin = interactionPlugin;